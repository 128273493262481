import React from 'react';
import {stringsTranslate} from "../../locales/CustomTranslater";
import {words} from "../../locales/Words";
import {Animated} from "react-animated-css";
import {BiQrScan} from "react-icons/bi";
import {GoEye} from "react-icons/go";
import {GiProgression, GiShakingHands} from "react-icons/gi";
import {FiSettings} from "react-icons/fi";




export default function FeatureSection() {
    const initData = {
        heading: stringsTranslate(words.words.features),
        featureDataTwo: [
            {
                "id": 1,
                "iconClass": <BiQrScan size={50}/>,
                "title": stringsTranslate(words.words.easy_for_everyone),
                "text": "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Veritatis culpa expedita dignissimos."
            },
            {
                "id": 4,
                "iconClass": <GoEye size={50}/>,
                "title": stringsTranslate(words.words.extra_views),
                "text": "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Veritatis culpa expedita dignissimos."
            },
            {
                "id": 2,
                "iconClass": <GiProgression size={50}/>,
                "title": stringsTranslate(words.words.fast_sell),
                "text": "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Veritatis culpa expedita dignissimos."
            },
            {
                "id": 3,
                "iconClass": <FiSettings size={50}/>,
                "title": stringsTranslate(words.words.car_info_overview),
                "text": "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Veritatis culpa expedita dignissimos."
            },
            {
                "id": 4,
                "iconClass": <GiShakingHands size={50}/>,
                "title": stringsTranslate(words.words.faster_deal),
                "text": "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Veritatis culpa expedita dignissimos."
            }
        ]
    }



        return (
            <section id="features" className="section features-area ptb_100">
                <div className="container-fluid">
                    <div className="row justify-content-center">
                    <div className="col-12 col-md-10 col-lg-6">
                        {/* Section Heading */}
                        <div className="section-heading text-center">
                        <h2>{initData.heading}</h2>
                        {/*<p className="d-none d-sm-block mt-4">{initData.headingText}</p>*/}
                        {/*<p className="d-block d-sm-none mt-4">{initData.headingTexttwo}</p>*/}
                        </div>
                    </div>
                    </div>
                    <div className="row justify-content-center">
                    {initData.featureDataTwo.map((item, idx) => {
                        return(
                            <Animated key={`ft_${idx}`} className="col-12 col-md-6 col-lg-2"
                                      animationInDelay={200*idx} animationIn="bounceInRight" isVisible={true}>
                                {/* Icon Box */}
                                <div className="icon-box text-center p-4">
                                {/* Featured Icon */}
                                <div className="featured-icon mb-3">
                                    {item.iconClass}
                                </div>
                                {/* Icon Text */}
                                <div className="icon-text">
                                    <h3 className="mb-2">{item.title}</h3>
                                    {/*<p>{item.text}</p>*/}
                                </div>
                                </div>
                            </Animated>
                        );
                    })}
                    </div>
                </div>
            </section>
        );
    }


