import React  from 'react';
import {stringsTranslate} from "../../locales/CustomTranslater";
import {words} from "../../locales/Words";
import {Link} from "react-router-dom";
import {Animated} from "react-animated-css";


export default function Example({price}) {
    const initData = {
        "heading": stringsTranslate(words.words.example_title),
        "buttonText": stringsTranslate(words.words.request_your_sticker),
        "example": "/img/extra/qr-v-example.png",
        "example_qr": "/img/extra/SVGRepo_iconCarrier.png",

    }

        return (
            <section id={"example"} className="section service-area bg-white overflow-hidden mt-5">
                <div className="container-fluid mt-5">
                    <div className="row align-content-center align-items-center">
                    <div className="col-12 col-md-7 text-md-right text-lg-right text-center">
                        {/* Service Thumb */}
                        <Animated className="" animationInDelay={250} animationIn="jackInTheBox" isVisible={true}>
                            <img className={"car-img-example border-secondary"} src={initData.example} alt="" />
                        </Animated>
                    </div>
                        <div className="col-12 col-md-5 text-md-left text-lg-left text-center pb-2">
                            {/* Service Text */}
                            <div className="service-text pt-4 pt-lg-0">
                                <img className={"border-secondary"} width={75} src={initData.example_qr} alt=""/>
                                <h3 className="mt-3 text-dark">{initData.heading}</h3>

                                <Link to="/sticker-application"
                                      className="btn btn-bordered mt-3">{initData.buttonText}</Link>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        );
    }
