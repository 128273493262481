import React, {useEffect, useState} from 'react';
import {hasAValue} from "../uitls/SharedFunctions";
import {addVehicleCall, authCarCall, getPricesCall} from "../api/ApiCalls";
import {toastOptions} from "../config/constants";
import {toast} from "react-toastify";
import {Animated} from "react-animated-css";
import {stringsTranslate} from "../locales/CustomTranslater";
import {words} from "../locales/Words";
import {getAnalytics, logEvent} from "firebase/analytics";
import Swal from "sweetalert";


export default function StickerApplication() {
    const initData = {
        content: "There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable. If you are going to use a passage of Lorem Ipsum.",
        formHeading: "Your QR Application form",
        "cost": stringsTranslate(words.words.cost),
        "order": stringsTranslate(words.words.order),
        "orderInfo": stringsTranslate(words.words.orderInfo),
        "qr_code_sticker_of": stringsTranslate(words.words.qr_code_sticker_of),
        qr_code_sticker: stringsTranslate(words.words.qr_code_sticker),
        delivery_cost: stringsTranslate(words.words.delivery_cost),
        tax: stringsTranslate(words.words.tax),
        total: stringsTranslate(words.words.total),
        formText: "Don't have an account?",
        btnText: "Reqeuest QR stiker",
        "thumbTwo2": "/img/extra/affordable-for-all.png",
    }

    const [email, setEmail] = useState(undefined)
    const [address, setAddress] = useState(undefined)
    const [postalCode, setPostalCode] = useState(undefined)
    const [city, setCity] = useState(undefined)
    const [linkToWebSite, setLinkToWebSite] = useState(undefined)
    const [licensePlate, setLicensePlate] = useState(undefined)
    const [code, setCode] = useState(undefined);
    const [price, setPrice] = useState(undefined);


    const [lockEmail, setLockEmail] = useState(false);

    useEffect(() => {
           getPrices()
    }, [])


    const analytics = getAnalytics();
    logEvent(analytics, "Sticker application")

    return (
        <div className="bg-overlay min-vh-100 ">
            <section className="section discover-area overflow-hidden ptb_150">
                <div className="container">
                    {/*<div className="row justify-content-end ">*/}
                    {/*</div>*/}
                    <div className="row justify-content-between align-items-center bg-gray application-card mt-5 mt-lg-0 mt-md-0 ">
                        <div className="col-12 ">
                            <div className="row justify-content-end">
                                <img className={"affordable-image-application-style"} src={initData.thumbTwo2} alt=""/>
                            </div>
                        </div>

                        {/* Order info -------------------*/}
                        <div className="col-12 col-lg-8 p-3 order-1  order-lg-0 order-xl-0 bg-white application-card">
                            <div className="contact-box text-center">
                                {/* Contact Form */}
                                {!lockEmail &&
                                    <Animated animationIn="bounceInLeft" animationOut="fadeOut" isVisible={true}>
                                        <div id="contact-form">
                                            <div className="contact-top mb-5">

                                                <h2 className="text-left application-title-style">{stringsTranslate(words.words.request_your_sticker)}</h2>
                                                <h5 className="text-left application-info-style">{stringsTranslate(words.words.sell_you_car_in_few)}</h5>
                                            </div>

                                            <div className="row">


                                                <h3 className="contact-title application-sub-title-style col-12 mb-3 text-left py-3">{stringsTranslate(words.words.your_car_info)}</h3>

                                                <div className="col-lg-6 col-md-12 col-sm-11 text-left">
                                                    {/*license Plate*/}
                                                    <label htmlFor="kenteken"
                                                           className="form-label label-form-style fw-5">{stringsTranslate(words.words.license_plate)}</label>

                                                    <div className="input-group mb-3">

                                                        <input type="text"
                                                               className="form-control fw-5 license-plate-input"
                                                               name="kenteken"
                                                               required="required"
                                                               maxLength={6}
                                                               onChange={(val) => setLicensePlate(val.target.value.toString().toUpperCase())}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-lg-6 col-md-12 col-sm-11 text-left">
                                                    {/*Link*/}
                                                    <label htmlFor="url"
                                                           className="form-label label-form-style fw-5">{stringsTranslate(words.words.link_to_the_website)}</label>
                                                    <div className="input-group mb-3">
                                                        <input type="url" className="form-control fw-5"
                                                               name="url"
                                                               required="required"
                                                               maxLength={400}
                                                               onChange={(val) => setLinkToWebSite(val.target.value)}
                                                        />
                                                    </div>
                                                </div>

                                                <h3 className="contact-title application-sub-title-style col-12 mb-3 text-left py-3">{stringsTranslate(words.words.your_personal_info)}</h3>
                                                <div className="col-lg-6 col-md-12 col-sm-11 text-left">
                                                    {/*Email*/}
                                                    <label htmlFor="email"
                                                           className="form-label label-form-style fw-5">{stringsTranslate(words.words.email)}</label>
                                                    <div className="input-group mb-3">

                                                        <input type="email" className="form-control fw-5"
                                                               name="email"
                                                               required="required"
                                                               maxLength={45}
                                                               onChange={(val) => setEmail(val.target.value)}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-lg-6 col-md-12 col-sm-11 text-left">
                                                    {/*Address*/}
                                                    <label htmlFor="address"
                                                           className="form-label label-form-style fw-5">{stringsTranslate(words.words.address)}</label>
                                                    <div className="input-group mb-3">

                                                        <input type="text" className="form-control fw-5"
                                                               name="address"
                                                               required="required" maxLength={40}
                                                               onChange={(val) => setAddress(val.target.value)}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-lg-6 col-md-12 col-sm-11 text-left">
                                                    {/*Postal code*/}
                                                    <label htmlFor="postalCode"
                                                           className="form-label label-form-style fw-5">{stringsTranslate(words.words.postal_code)}</label>
                                                    <div className="input-group mb-3">

                                                        <input type="text" className="form-control fw-5"
                                                               name="postalCode"
                                                               required="required"
                                                               maxLength={6}
                                                               onChange={(val) => setPostalCode(val.target.value.toString().toUpperCase())}
                                                        />

                                                    </div>
                                                </div>
                                                <div className="col-lg-6 col-md-12 col-sm-11 text-left">
                                                    {/*City*/}
                                                    <label htmlFor="city"
                                                           className="form-label label-form-style fw-5">{stringsTranslate(words.words.city)}</label>
                                                    <div className="input-group mb-3">

                                                        <input type="text" className="form-control fw-5"
                                                               name="city"
                                                               required="required" maxLength={25}
                                                               onChange={(val) => setCity(val.target.value)}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-lg-6 col-md-12 col-sm-11 text-left">
                                                    <button className="btn btn-bordered mt-3 mt-sm-4"
                                                            onClick={() => {
                                                                addCar()
                                                            }}
                                                    >{stringsTranslate(words.words.request_your_sticker)}</button>
                                                </div>
                                            </div>
                                        </div>
                                    </Animated>}

                                {/*Code Auth ----------------------------*/}
                                {lockEmail &&
                                    <Animated animationIn="bounceInLeft" animationOut="fadeOut" isVisible={true}>
                                        <div id="contact-form">

                                            <div className="row">
                                                <h3 className="contact-title application-sub-title-style col-12 mb-3 text-left py-3">{stringsTranslate(words.words.fill_in_the_code_that_you_received)}</h3>


                                                <div className="col-md-6 col-sm-12 text-left">
                                                    {/*Code*/}
                                                    <label htmlFor="code"
                                                           className="form-label label-form-style fw-5">{stringsTranslate(words.words.code)}</label>
                                                    <div className="input-group mb-3">
                                                        <input type="text" name={"code"}
                                                               className="form-control fw-5"
                                                               value={code} required="required" maxLength={25}
                                                               onKeyPress={(val) => {
                                                                   if (val.key === "Enter") {
                                                                       auth()
                                                                   }
                                                               }}
                                                               onChange={(e) =>
                                                                   setCode(e.target.value)
                                                               }
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-12 text-left">
                                                    <button className="btn btn-bordered mt-3 mt-sm-4"
                                                            onClick={() => {
                                                                auth()
                                                            }}
                                                    >{stringsTranslate(words.words.check_code)}</button>
                                                </div>
                                            </div>
                                        </div>
                                    </Animated>}
                            </div>
                        </div>


                        {/* Sticker & price -----------------------*/}
                        <div className={"col-12 col-lg-4 w-100 h-100 order-0  order-lg-1 order-xl-1 my-4"}>
                            <div className="contact-top">

                                <h3 className="contact-title application-sub-title-style text-left"> {initData.orderInfo} </h3>
                                <h5 className="text-secondary-alt fw-4 py-3">{initData.formContent}</h5>
                            </div>
                            <div className="input-group d-flex align-items-center justify-content-center">
                                <img src="/img/logo/new-logo/qr-website.png" className={"border-radius-img-no-white"} alt="" width="150"
                                     height="55"/>
                            </div>

                            <ul className="list-group mt-4">
                                <li className="d-flex justify-content-between align-items-center">
                                    <h3 className={"price-text-style"}>{initData.qr_code_sticker} </h3>
                                    <span className="badge">{hasAValue(price) &&
                                        <h3 className={"price-style"}>{price.subtotalPrice} </h3>}</span>
                                </li>

                                <li className="d-flex justify-content-between align-items-center">
                                    <h3 className={"price-text-style"}>{initData.delivery_cost} </h3>
                                    <span className="badge">{hasAValue(price) &&
                                        <h3 className={"price-style"}>{price.deliveryCost} </h3>}</span>
                                </li>

                                <li className="d-flex justify-content-between align-items-center border-top mt-3">
                                    <h3 className={"mt-3 price-style"}>{initData.total} </h3>
                                    <span className="badge">{hasAValue(price) &&
                                        <h3 className={" price-style"}>{price.totalPrice} </h3>}</span>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );

    function addCar() {
        let url = linkToWebSite
    let postal_code = postalCode
        let license_plate = licensePlate
        let data = {license_plate, address, url, email, postal_code, city}

        if (hasAValue(licensePlate) && hasAValue(address) && hasAValue(url) && hasAValue(email) && hasAValue(postal_code) && hasAValue(city)) {
            if (!validURL(url)) {
                toast.error(stringsTranslate(words.words.url_is_not_valid), toastOptions)
            } else {

                Swal({
                    title: stringsTranslate(words.words.place_an_order),
                    text: stringsTranslate(words.words.sure_from_all_info),
                    icon: "success",
                    buttons: true,
                }).then((response) => {
                    if (response) {

                        addVehicleCall(data).then(results => {
                            if (results.success === false) {
                                toast.error(results.message, toastOptions)
                            } else {
                                setLockEmail(true)
                                toast.success(results.message, toastOptions)
                            }
                        }).catch(error => {
                            if (hasAValue(error.response)) {
                                toast.error(error.response.data.error, toastOptions)
                            }
                        })
                    } else {
                        toast.error(stringsTranslate(words.words.all_fields_are_required), toastOptions)
                    }
                })
            }

        } else {
            toast.error(stringsTranslate(words.words.all_fields_are_required), toastOptions)

        }
    }

    function auth() {
        let data = {email, code}
        if (hasAValue(code)) {
            authCarCall(data).then(success => {
                console.log(success)
                toast.success(success.message, toastOptions);
                setTimeout(() => {
                    window.location = "/sticker-order-page/" + success.car_code
                }, 3000)
            }).catch(error => {
                toast.error(error.response.data.error, toastOptions);
            })
        } else {
            toast.error(stringsTranslate(words.words.all_fields_are_required), toastOptions);
        }
    }


    function getPrices() {
        getPricesCall().then(success => {
            setPrice(success)
        }).catch(error => {
            toast.error(error.response.data.error, toastOptions);
        })
    }

    function validURL(str) {
        let pattern = new RegExp('^(https?:\\/\\/)?' + // protocol
            '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
            '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
            '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
            '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
            '(\\#[-a-z\\d_]*)?$', 'i'); // fragment locator
        return !!pattern.test(str);
    }


}
