import React  from 'react';
import {stringsTranslate} from "../../locales/CustomTranslater";
import {words} from "../../locales/Words";
import {Animated} from "react-animated-css";


export default function ServiceSection({price}) {
    const initData = {
        "heading": stringsTranslate(words.words.unique_qr_code),
        "pay_once": stringsTranslate(words.words.pay_once),
        "pricing": stringsTranslate(words.words.price),
        "buttonText": stringsTranslate(words.words.request_your_sticker),
        "thumbTwo": "/img/extra/IMG_5827.png",
        "thumbTwo1": "/img/extra/IMG_5815.png",
        "thumbTwo2": "/img/extra/affordable-for-all.png",

    }

        return (
            <section id={"service"} className="section service-area background-unique overflow-hidden ptb_100">
                <div className="container">
                    <div className="row justify-content-center align-content-center align-items-center text-center">
                    <div className="col-12">
                        {/* Service Text */}
                        <div className="service-text pt-4 pt-lg-0">

                            <h2 className="mb-4">{initData.heading}</h2>
                            <h3 className="mb-4 text-primary">{initData.pay_once}
                                <a href="/sticker-application" className="text-secondary"> {initData.buttonText}</a>
                            </h3>
                            {/*{hasAValue(price) && <h3 className="text-danger text-opacity-25">{initData.pricing} {price.subtotalPrice}<a className={"h5"}></a></h3>}*/}

                        </div>
                    </div>
                    <div className="col-12">
                        {/* Service Thumb */}
                        <Animated className="" animationInDelay={250} animationIn="jackInTheBox" isVisible={true}>
                            <img className={"border-radius-img car-img"} src={initData.thumbTwo1} alt="" />
                            <img className={"affordable-image-style"} src={initData.thumbTwo2} alt="" />
                        </Animated>
                    </div>
                    </div>
                </div>
            </section>
        );
    }
